import Vue from 'vue'

export default class Errors {
    constructor() {
        this.messages = {}
    }

    has(field) {
        if (typeof this.messages[field] !== 'undefined') {
            return true
        } else {
            return false
        }
    }

    any() {
        return Object.keys(this.messages).length > 0
    }

    get(field) {
        if (this.messages[field]) {
            if (Array.isArray(this.messages[field])) {
                let msg = ''
                for (let i = 0; i < this.messages[field].length; i++) {
                    msg += '<div>' + this.messages[field][i] + '</div>'
                }
                return msg
            } else {
                return this.messages[field]
            }
        }
    }

    set(field, value) {
        Vue.set(this.messages, field, value)
    }

    record(messages) {
        this.messages = messages
    }

    clear(field) {
        if (field) {
            Vue.delete(this.messages, field)
            return
        }

        this.messages = {}
    }
}
